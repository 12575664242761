import React, { useState } from 'react'
import BreakoutContentCard, { BreakoutContentRow } from '../BreakoutContent';
import styled from 'styled-components';
import { createMarkup } from '../../util/functions';
import { Heading, Text } from '../../util/typography';
import * as CTA from '../../util/CTA';

const FootLinkContainer = styled.div`
padding-top: 15px; 
text-align: ${({ shouldCenter }) => (shouldCenter ? 'center' : 'left')};
`;

const LinkContainer = styled.div`
.noBreak {
 white-space: nowrap;
}
`;

const StyledTertiaryButton = styled(CTA.TertiaryButton)`
display:  ${({ shouldCenter }) => (!shouldCenter && 'flex')};
justify-content: space-between;
`;

const StyledDivider = styled.div`
border-top: 1px solid #BEBEBE;
margin-top: 15px;
padding-top: 15px;
`;

const CardHead = styled(Heading.H4)`
display:block;
text-align: ${({ shouldCenter }) => (shouldCenter ? 'center' : 'left')};

* + & {
margin-bottom: 6px;
}
`;

const CardContent = styled(Text.Body)`
display:block;
text-align: ${({ shouldCenter }) => (shouldCenter ? 'center' : 'left')};

* + & {
margin-top: 6px;
}`;


const EarlierStageGrid = (props) => {
    const [isCardHovered, setIsCardHovered] = useState(false);

    return (
        <BreakoutContentCard
            icon={() => <img src={props.img}
                alt=""
                width={60}
                height={60} />}
            columns={3}
            card='earlyStage'
            isClickable={props.isClickable}
            url={props.isClickable ? props.keynote[0].url : ''}
            setIsCardHovered={setIsCardHovered}
        >
            <div>
                <CardHead as='H4' dangerouslySetInnerHTML={createMarkup(`${props.head}`)} shouldCenter={props.shouldCenter} />
                <Text.Eyebrow>{props.subHead}</Text.Eyebrow>
                <CardContent shouldCenter={props.shouldCenter}>{props.content}</CardContent>
            </div>

            <FootLinkContainer shouldCenter={props.shouldCenter}>
                {props.keynote.map((keynoteItem, index) => (<LinkContainer >
                    {index === 2 && <StyledDivider />}
                    <StyledTertiaryButton url={keynoteItem.url} label={keynoteItem.label} parentHovered={props.isClickable && isCardHovered} shouldCenter={props.shouldCenter} />
                </LinkContainer>))}
            </FootLinkContainer>
        </BreakoutContentCard>
    )
}

export default EarlierStageGrid;
