import React from 'react'
import DefaultLayout from '../../layouts/DefaultLayout';
import { jobCodes } from '../../util/data/jobCodes';
import TemplateColumn from '../../layouts/TemplateColumn';
import { BreakoutContentRow, PageHeader, PageIntro, PageSection, PrimaryCallOut, RelatedContent } from '../../components';
import EarlierStageGrid from '../../components/EarlierStageGrid';

// SEO INFORMATION
const pageMeta = {
    title: `Earlier-Stage Indications for KEYTRUDA® (pembrolizumab) | Health Care Professionals`,
    keywords: `keytruda, earlier-stage cancer`,
    description: `Health care professionals may find efficacy and safety results for 6 earlier-stage cancers, and consider appropriate patients for earlier treatment with KEYTRUDA® (pembrolizumab).`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/indication/earlier-stage-cancers/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/indication/earlier-stage-cancers/","@type":"MedicalWebPage","name":"KEYTRUDA® (pembrolizumab) Is FDA-Approved for 6 Indications in Earlier Stages of Cancer","description":"Explore efficacy and safety results and consider KEYTRUDA for appropriate patients. "}`
    ]
}

const jobCode = jobCodes[37].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/hcp-and-patient-resources/',
            label: 'Patient & HCP Resources'
        }
    },
    {
        section: 'Resources',
        link: {
            url: '/resources/key-you-patient-support-program/',
            label: 'Patient Support Program'
        }
    }
]

const earlierStageTriage = [
    {
        img: '/static/images/badge-lungs.png',
        head: 'Neoadjuvant and Adjuvant Therapy for Non–Small Cell Lung Cancer (NSCLC)',
        /*subHead: 'Neoadjuvant and Adjuvant Therapy',*/
        content: 'KEYTRUDA is indicated for the treatment of patients with resectable (tumors ≥4 cm or node positive) non–small cell lung cancer (NSCLC) in combination with platinum⁠-⁠containing chemotherapy as neoadjuvant treatment, and then continued as a single agent as adjuvant treatment after surgery.',
        keynote: [
            { label: <>View Efficacy Data From <span className='noBreak'>KEYNOTE-671</span></>, url: '/efficacy/nsclc-neoadjuvant-therapy/'},
            { label: <>View Selected Adverse Reaction Data From <span className='noBreak'>KEYNOTE-671</span></>, url: '/safety/adverse-reactions/nsclc-neoadjuvant-therapy/' }
        ]
    },
    {
        img: '/static/images/badge-lungs.png',
        head: 'Adjuvant Therapy for Non–Small Cell Lung Cancer (NSCLC)',
        /*subHead: 'Adjuvant Therapy',*/
        content: 'KEYTRUDA, as a single agent, is indicated for adjuvant treatment following resection and platinum-based chemotherapy for adult patients with stage IB (T2a ≥4 cm), II, or IIIA non–small cell lung cancer (NSCLC).',
        keynote: [
            { label: <>View Efficacy Data From <span className='noBreak'>KEYNOTE-091</span></>, url: '/efficacy/nsclc-adjuvant-therapy/' },
            { label: <>View Selected Adverse Reaction Data From <span className='noBreak'>KEYNOTE-091</span></>, url: '/safety/adverse-reactions/nsclc-adjuvant-therapy/' }
        ]
    },
    {
        img: '/static/images/badge-breast.png',
        head: 'High-Risk Early-Stage Therapy for Triple-Negative Breast Cancer (TNBC)',
        /*subHead: 'High-Risk Early-Stage Therapy',*/
        content: 'KEYTRUDA is indicated for the treatment of patients with high-risk early-stage triple-negative breast cancer (TNBC) in combination with chemotherapy as neoadjuvant treatment, and then continued as a single agent as adjuvant treatment after surgery.',
        keynote: [
            { label: <>View Efficacy Data From <span className='noBreak'>KEYNOTE-522</span></>, url: '/efficacy/early-stage-triple-negative-breast-cancer/'},
            { label: <>View Selected Adverse Reaction Data From <span className='noBreak'>KEYNOTE-522</span></>, url: '/safety/adverse-reactions/early-stage-triple-negative-breast-cancer/' }
        ]
    },
    {
        img: '/static/images/badge-melanoma-icon.png',
        head: 'Adjuvant Therapy for Melanoma ',
        /*subHead: 'Adjuvant Therapy',*/
        content: 'KEYTRUDA is indicated for the adjuvant treatment of adult and pediatric (12 years and older) patients with stage IIB, IIC, or Ill melanoma following complete resection.',
        keynote: [
            { label: <>View Efficacy Data From <span className='noBreak'>KEYNOTE-716</span></>, url: '/efficacy/melanoma-adjuvant-therapy/#clinical-findings-from-keynote-716-stage-iib-iic'},
            { label: <>View Selected Adverse Reaction Data From <span className='noBreak'>KEYNOTE-716</span></>, url: '/safety/adverse-reactions/melanoma-adjuvant-therapy/' },
            { label: <>View Efficacy Data From <span className='noBreak'>KEYNOTE-054</span></>, url: '/efficacy/melanoma-adjuvant-therapy/#clinical-findings-from-keynote-054-stage-iii'},
            { label: <>View Selected Adverse Reaction Data From <span className='noBreak'>KEYNOTE-054</span></>, url: '/safety/adverse-reactions/melanoma-adjuvant-therapy/' }
        ]
    },
    {
        img: '/static/images/badge-bacillus-icon.png',
        head: 'High-Risk Non-Muscle Invasive Bladder Cancer (NMIBC)',
        /*subHead: 'High-Risk',*/
        content: 'KEYTRUDA, as a single agent, is indicated for the treatment of patients with Bacillus Calmette-Guerin (BCG)-unresponsive, high-risk, non-muscle invasive bladder cancer (NMIBC) with carcinoma in situ (CIS) with or without papillary tumors who are ineligible for or have elected not to undergo cystectomy.',
        keynote: [
            { label: <>View Efficacy Data From <span className='noBreak'>KEYNOTE-057</span></>, url: '/efficacy/nmibc/'},
            { label: <>View Selected Adverse Reaction Data From <span className='noBreak'>KEYNOTE-057</span></>, url: '/safety/adverse-reactions/nmibc/' }
        ]
    },
    {
        img: '/static/images/badge-kidney-icon.png',
        head: 'Adjuvant Therapy for Renal Cell Carcinoma (RCC)',
        /*subHead: 'Adjuvant Therapy',*/
        content: 'KEYTRUDA is indicated for the adjuvant treatment of patients with renal cell carcinoma (RCC) at intermediate-high or high risk of recurrence following nephrectomy, or following nephrectomy and resection of metastatic lesions.',
        keynote: [
            { label: <>View Efficacy Data From <span className='noBreak'>KEYNOTE-564</span></>, url: '/efficacy/adjuvant-renal-cell-carcinoma/' },
            { label: <>View Selected Adverse Reaction Data From <span className='noBreak'>KEYNOTE-564</span></>, url: '/safety/adverse-reactions/adjuvant-renal-cell-carcinoma/'}
        ]
    },
    {
        img: '/static/images/badge-information.png',
        head: 'Selected Indications',
        content: 'Learn about additional, select indications for KEYTRUDA.',
        keynote: [
            { label: 'View Select Indications', url: '/approved-indications/' },
        ], 
        shouldCenter: true,
        isClickable: true,
    }
]


const Page = ({ location }) => {
    return (
        <DefaultLayout pageMeta={pageMeta}  location={location} jobCode={jobCode} >
            <div data-template-name="template-d">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-b-intro" maxWidth={824}>
                    <PageHeader title="Earlier-Stage Indications" />
                    <PageSection title="KEYTRUDA® (pembrolizumab) Is FDA-Approved for 6 Indications in Earlier Stages of Cancer" bgColor="white" centerTitle={true}>
                        <PageIntro centered={true}>
                            <p>Explore efficacy and safety results and consider KEYTRUDA for appropriate patients.</p>
                        </PageIntro>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                {/* Begin Main Column */}
                <PageSection bgColor="mist">
                    <BreakoutContentRow>
                        {earlierStageTriage.map((listItem, index) => (
                            <EarlierStageGrid
                                key = {index}
                                img={listItem.img}
                                head={listItem.head}
                                subHead={listItem.subHead}
                                content={listItem.content}
                                keynote={listItem.keynote}
                                shouldCenter = {!!listItem.shouldCenter && listItem.shouldCenter}
                                isClickable = {!!listItem.isClickable && listItem.isClickable}
                            />
                        ))}
                    </BreakoutContentRow>
                </PageSection>
                <PageSection bgColor="gradient">
                    <PrimaryCallOut
                        title="Mechanism of Action"
                        content="See how KEYTRUDA® (pembrolizumab) works."
                        primaryAction={{ label: 'View MOA', url: '/resources/mechanism-of-action/' }}
                    />
                </PageSection>
                <PageSection>
                    <PrimaryCallOut
                        title="Ask Merck"
                        contentMaxWidth={500}
                        content="Merck is committed to helping you get your product questions answered. Connect with a Merck Associate now."
                        secondaryAction={{ label: 'Get Connected', url: 'https://www.askmerckoncology.com' }}
                    />
                </PageSection>
                <PageSection bgColor="mist">
                    <PrimaryCallOut
                        title="Patient Access & Reimbursement Support"
                        contentMaxWidth={500}
                        content="The Merck Access Program may help answer questions about billing and coding for KEYTRUDA, and co-pay assistance for eligible patients."
                        secondaryAction={{ label: 'Learn About The Merck Access Program', url: 'https://www.merckaccessprogram-keytruda.com/' }}
                    />
                </PageSection>
                <PageSection bgColor="keytrudaGreen">
                    <RelatedContent items={relatedContent} head="You Might Be Interested In" />
                </PageSection>
            </div>
        </DefaultLayout>
    )
}

export default Page;
